const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://dawi-app.com' : 'http://192.168.100.106:3040',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://dawi-app.com' : 'http://192.168.100.106:3040',
    api: 'api/',
    apiSocket: 'dawi::1.2',
    nameDir: 'dawi',
    package: 'co.dawiapp.web',
    version: '1.0.1',
    googleAPIKey: 'AIzaSyDjZz9wDRTqWD3pDlT1fcMpp-ZftxgiCE4',
    appName: 'Dawi',
    provider: '',
    colorPrimary: '#FFB032',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'com.dawiapp.passenger', 
    playStoreDriverId: 'com.dawiapp.driver',
    appStorePassengerId: '6503261111',
    appStoreDriverId: '6503261159',
    email: "dawigroup06@gmail.com",
};
export default config;
